import { RootState } from '@/lib/store';
import { setNotificationModalOpenState } from '@/lib/store/notifications/actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModalNavigation } from '../modals/useModalNavigation';
import { AuthService } from '@/services/AuthService';
import { ModalsKey } from '@/enums/modalsKey';

export function useToggleSupportModal(): () => void {
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.notifications.open,
  );
  const { navigateToModal } = useModalNavigation();

  const handleToggleSupportModal = useCallback(() => {
    if (!AuthService.isAuthorized()) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    if (!open) {
      dispatch(setNotificationModalOpenState(true));
      return;
    }

    dispatch(setNotificationModalOpenState(false));
  }, [dispatch, open, navigateToModal]);

  return handleToggleSupportModal;
}
