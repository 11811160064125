export const containerVariants = {
  exit: {
    x: '-100%',
  },
  open: {
    x: 0,
  },
};

export const containerTransition = { type: 'spring', bounce: 0, duration: 0.7 };
