import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { useToggleSupportModal } from '@/hooks/support/useToggleSupportModal';
import styles from './styles.module.scss';
import NavbarButton from '../../Item/Button/index.';
import { useNotificationGroupsMe } from '@/api/notification-groups';

export default function NotificationButton() {
  const open: boolean = useSelector(
    (state: RootState) => state.notifications.open,
  );
  const handleToggleModal: () => void = useToggleSupportModal();
  const { isPending, unreadCount } = useNotificationGroupsMe();

  return (
    <NavbarButton
      onClick={handleToggleModal}
      disabled={open}
      active={open}
      isLoading={isPending || typeof unreadCount === 'undefined'}
      icon="fas fa-envelope fa-swap-opacity"
      label="tab_notification"
    >
      {unreadCount > 0 && (
        <div className={styles.indicator}>
          {unreadCount > 99 ? '99+' : unreadCount}
        </div>
      )}
    </NavbarButton>
  );
}
