import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function NavbarItemSkeleton() {
  return (
    <div className={`${styles.container} ${styles['container--disabled']}`}>
      <div className={skeleton.icon} />
      <div className={skeleton.text} />
    </div>
  );
}
