import { useSelector } from 'react-redux';
import { Wrapper } from './styles';
import NotificationsModal from '@/components/Notifications/NotificationModal';
import { RootState } from '@/lib/store';
import { AnimatePresence, m as motion } from 'framer-motion';
import { useClickOutside } from '@starsoft/common/hooks';
import { containerTransition, containerVariants } from '../../../variants';
import { useUserAccount } from '@/api/user/queries';
import { useToggleSupportModal } from '@/hooks/support/useToggleSupportModal';
import { useIsMobile } from '@starsoft/common/hooks';

export default function NotificationModal() {
  const open: boolean = useSelector(
    (state: RootState) => state.notifications.open,
  );
  const isMobile: boolean = useIsMobile();
  const { user } = useUserAccount();
  const handleToggleModal = useToggleSupportModal();
  const ref = useClickOutside({
    open: open && isMobile,
    onClickOutside: handleToggleModal,
  });

  if (!user) {
    return null;
  }

  return (
    <AnimatePresence>
      {open && (
        <Wrapper
          ref={ref}
          as={motion.div}
          variants={containerVariants}
          initial="exit"
          animate="open"
          exit="exit"
          transition={containerTransition}
        >
          <NotificationsModal />
        </Wrapper>
      )}
    </AnimatePresence>
  );
}
