import { Image } from '@starsoft/common/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;
  height: auto;
  padding-bottom: 58px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 58px;
  left: 0;
  z-index: 115;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.vars.palette.background.paper};
`;

export const TabContainer = styled.div`
  width: 100%;
  padding: 8px 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 115;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0 24px;
  padding-top: 16px;
`;

export const Logo = styled(Image)`
  width: 32px;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.vars.palette.background.default};
  color: ${({ theme }) => theme.vars.palette.text.secondary};
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 115;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-phone {
    height: 100%;
    max-height: 100%;
    overflow-y: visible;
  }
`;
