import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RootState } from '@/lib/store';
import MenuButton from './Menu/Button';
import LanguageButton from './Language/Button';
import NavbarLink from './Item/Link';
import NotificationButton from './Notification/Button';
import NavbarBonusLink from './Bonus';
import { useModal } from '@package/hooks';
import { ModalsKey } from '@/enums/modalsKey';

export default function MobileNavigationNavbar() {
  const { isOpen: isLanguageOpen } = useModal(ModalsKey.Language);
  const open = useSelector(
    (state: RootState) => state.sideBar.open || state.notifications.open,
  );

  return (
    <div
      className={`${styles.container} ${open || isLanguageOpen ? styles['container--open'] : ''}`}
    >
      <MenuButton />
      <NavbarLink
        label="tab_home"
        icon="fa-solid fa-house fa-swap-opacity"
        href="/"
      />
      <NavbarBonusLink />
      <LanguageButton />
      <NotificationButton />
    </div>
  );
}
