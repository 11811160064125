import { RootState } from '@/lib/store';
import { AnimatePresence, m as motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseButton,
  Logo,
  Overlay,
  Row,
  TabContainer,
  Wrapper,
} from './styles';
import { useClickOutside } from '@starsoft/common/hooks';
import { containerTransition, containerVariants } from './variants';
import { platformAssets } from '@/utils/assets';
import Tab from '@/components/Tab';
import SideBar from '@/components/SideBar';
import { useEffect } from 'react';
import Link from '@/components/core/Link';
import { toggleOpenSidebar } from '@/lib/store/side-bar/actions';
import { usePathname } from '@/hooks/usePathname';

export default function MenuModal() {
  const dispatch = useDispatch();
  const pathname: string = usePathname();
  const open = useSelector((state: RootState) => state.sideBar.open);
  const ref = useClickOutside({
    open,
    onClickOutside: toggle,
  });

  function toggle() {
    dispatch(toggleOpenSidebar());
  }

  function handleRouteChange() {
    if (!open) {
      return;
    }

    toggle();
  }

  //eslint-disable-next-line
  useEffect(handleRouteChange, [pathname, dispatch]);

  return (
    <AnimatePresence>
      {open && (
        <>
          <Overlay />
          <Wrapper
            ref={ref}
            as={motion.div}
            variants={containerVariants}
            initial="exit"
            animate="open"
            exit="exit"
            transition={containerTransition}
          >
            <Row>
              <Link href="/">
                <Logo
                  src={platformAssets.logo.icon}
                  alt="logo"
                  format="square"
                  width={32}
                  height={32}
                  secondarySkeleton
                />
              </Link>
              <CloseButton onClick={toggle}>
                <i className="fas fa-times" />
              </CloseButton>
            </Row>
            <TabContainer>
              <Tab isSecondary />
            </TabContainer>
            <SideBar />
          </Wrapper>
        </>
      )}
    </AnimatePresence>
  );
}
