import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import NavbarButton from '../../Item/Button/index.';
import { toggleOpenSidebar } from '@/lib/store/side-bar/actions';

export default function MenuButton() {
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.sideBar.open);

  function openMenu() {
    dispatch(toggleOpenSidebar());
  }

  return (
    <NavbarButton
      onClick={openMenu}
      disabled={open}
      active={open}
      icon="fas fa-bars"
      label="tab_menu"
    />
  );
}
