import useTranslation from 'next-translate/useTranslation';
import NavbarItemSkeleton from '../Skeleton';
import styles from '../styles.module.scss';
import { NavbarButtonProps } from './props';

export default function NavbarButton({
  className,
  children,
  isLoading,
  icon,
  label,
  active,
  ...props
}: NavbarButtonProps) {
  const { t } = useTranslation('common');

  if (isLoading) {
    return <NavbarItemSkeleton />;
  }

  return (
    <button
      className={`${styles.container} ${active ? styles['container--active'] : ''} ${!!className ? className : ''}`}
      {...props}
    >
      {children}

      {icon ? <i className={icon} /> : <div />}
      {t(label)}
    </button>
  );
}
