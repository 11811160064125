import NavbarButton from '../../Item/Button/index.';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { useModal } from '@package/hooks';

export default function LanguageButton() {
  const { navigateToModal } = useModalNavigation();
  const { isOpen } = useModal(ModalsKey.Language);

  function toggle() {
    navigateToModal(ModalsKey.Language);
  }

  return (
    <NavbarButton
      onClick={toggle}
      disabled={isOpen}
      active={isOpen}
      icon="fas fa-globe fa-swap-opacity"
      label="tab_language"
    />
  );
}
