import MenuModal from './Navbar/Menu/Modal';
import MobileNavigationNavbar from './Navbar';
import NotificationsModal from './Navbar/Notification/Modal';
import { useShowNavigation } from '@/hooks/useShowNavigation';

export default function MobileNavigation() {
  const showNavigation = useShowNavigation();

  if (!showNavigation) {
    return null;
  }

  return (
    <>
      <MenuModal />
      <NotificationsModal />
      <MobileNavigationNavbar />
    </>
  );
}
