import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 58px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 115;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: ${({ theme }) => theme.vars.palette.background.paper};

  & > div {
    width: 100%;
    height: 100%;
  }
`;
