import NavbarLink from '../Item/Link';

export default function NavbarBonusLink() {
  return (
    <NavbarLink
      label="notification_bonus_title"
      privateRoute
      href="/bonus"
      icon="fa-sharp fa-solid fa-gift"
      detatchedIcon
    />
  );
}
